# Var

scope.Var{scopeID: I, name: N, span: S, kind: K} :-
  var.exprVar{scopeID: I, name: N, span: S, kind: K} |
  var.callVar{scopeID: I, name: N, span: S, kind: K} |
  var.type{scopeID: I, name: N, span: S, kind: K} |
  # TODO: rename
  var.memberAccess2{scopeID: I, name: N, span: S, kind: K}.
var.exprVar{scopeID: Statement, name: N, span: S, kind: "var"} :-
  ast.Statement{id: Statement} &
  ast.Assign{id: Assign, parentID: Statement} &
  ast.Expr{id: Expr, parentID: Assign} &
  var.expr{id: Expr, name: N, span: S}.
var.expr{id: I, name: N, span: S} :-
  var.memberAccess{id: I, name: N, span: S} |
  var.bindAttr{id: I, name: N, span: S}.
var.memberAccess{id: Expr, name: N, span: span{from: Start, to: End}} :-
  ast.Expr{id: Expr} &
  ast.MemberAccess{id: MemberAccess, parentID: Expr, span: span{from: Start}} &
  ast.Ident{parentID: MemberAccess, text: N, span: span{from: Start, to: End}}.
var.bindAttr{id: Expr, name: N, span: span{from: Start, to: End}} :-
  ast.Expr{id: Expr} &
  ast.Bind{id: Bind, parentID: Expr} &
  ast.Attrs{id: Attrs, parentID: Bind} &
  ast.Attr{id: Attr, parentID: Attrs, span: span{to: End}} &
  ast.Ident{parentID: Attr, text: N, span: span{from: Start, to: End}}. # TODO: use 'key'
var.callVar{scopeID: Stmt, name: N, span: S, kind: "var"} :-
  ast.Statement{id: Stmt} &
  ast.Filter{id: Filter, parentID: Stmt} &
  ast.Ident{parentID: Filter, text: N, span: S}.
var.memberAccess2{scopeID: PrevT, name: N, span: S, kind: "attr"} :-
  tc.type.memberAccess.step{prevType: PrevT, type: T, node: Ident} &
  ast.Ident{id: Ident, span: S, text: N}.

var.type{scopeID: I, name: N, span: S, kind: "type"} :-
  var.getType{scopeID: I, name: N, span: S} |
  var.bindType{scopeID: I, name: N, span: S}.
var.getType{scopeID: global{}, name: N, span: S} :-
  ast.Get{id: Get} &
  ast.Ident{parentID: Get, text: N, span: S}.
var.bindType{scopeID: global{}, name: N, span: S} :-
  ast.Bind{id: Get} &
  ast.Ident{parentID: Get, text: N, span: S}.

# Defn

scope.Defn{scopeID: I, span: S, name: N, kind: K, type: T} :-
  defn.localVar{scopeID: I, span: S, name: N, kind: K, type: T} |
  defn.type{scopeID: I, span: S, name: N, kind: K, type: T} |
  defn.typeAttr{scopeID: I, span: S, name: N, kind: K, type: T}.

defn.localVar{scopeID: Statement, span: S, name: N, kind: "var", type: T} :-
  ast.Statement{id: Statement} &
  ast.Assign{id: Assign, parentID: Statement} &
  ast.Ident{parentID: Assign, span: S, text: N} &
  tc.Type{scope: Statement, var: N, type: T}.

defn.type{scopeID: global{}, span: S, name: N, kind: "type", type: "any"} :-
  ast.TypeDecl{id: Decl} &
  ast.Ident{parentID: Decl, text: N, span: S}.

defn.typeAttr{scopeID: Struct, span: S, name: N, kind: "attr", type: T} :-
  tc.typeAttr{struct: Struct, defnSpan: S, attr: N, type: T}.

defn.bindAttr{scopeID: Typ, span: S, name: N, kind: "attr", type: "any"} :-
  ast.Bind{id: Bind} &
  ast.Ident{parentID: Bind, text: Typ} &
  ast.Attrs{id: Attrs, parentID: Bind} &
  ast.Attr{id: Attr, parentID: Attrs} &
  ast.Ident{parentID: Attr, text: N, span: S, tag: "key"}.

# TODO: attrs from type

# Type Checking

tc.Type{scope: S, var: N, type: T} :-
  tc.type.get{scope: S, var: N, type: T} |
  tc.type.bind{scope: S, var: N, type: T} |
  tc.type.memberAccess{scope: S, var: N, type: T}.
tc.type.get{scope: Statement, var: N, type: T} :-
  util.AssignExpr{assign: Statement, var: N, expr: Expr} &
  ast.Get{id: Get, parentID: Expr} &
  ast.Ident{parentID: Get, text: T}.
tc.type.bind{scope: Statement, var: N, type: T} :-
  util.AssignExpr{assign: Statement, var: N, expr: Expr} &
  ast.Bind{id: Bind, parentID: Expr} &
  ast.Ident{parentID: Bind, text: T}.
tc.type.memberAccess{scope: Statement, var: N, type: T} :-
  tc.type.memberAccess.node{scope: Statement, var: N, type: T, node: Ident} &
  astInternal.lastChild{id: Ident}.
tc.type.memberAccess.base{scope: Statement, var: N, type: T, node: Ident} :-
  util.AssignExpr{assign: Statement, var: N, expr: Expr} &
  ast.MemberAccess{id: MemberAccess, parentID: Expr} &
  astInternal.firstChild{parentID: MemberAccess, id: Ident} &
  ast.Ident{id: Ident, text: Var} &
  astInternal.next{prev: ParentScope, next: Statement} &
  scope.Item{scopeID: ParentScope, name: Var, type: T}.
tc.type.memberAccess.node{scope: Statement, var: N, type: T, node: Ident} :-
  tc.type.memberAccess.base{scope: Statement, var: N, type: T, node: Ident} |
  tc.type.memberAccess.step{scope: Statement, var: N, type: T, node: Ident}.
tc.type.memberAccess.step{scope: Statement, var: N, prevType: PrevT, type: T, node: Ident} :-
  tc.type.memberAccess.node{scope: Statement, var: N, type: PrevT, node: Prev} &
  astInternal.next{prev: Prev, next: Ident} &
  ast.Ident{id: Ident, text: Attr} &
  tc.typeAttr{struct: PrevT, attr: Attr, type: T}.

# TODO: rename assign to statement
util.AssignExpr{assign: Stmt, var: Var, varSpan: S, expr: Expr} :-
  ast.Statement{id: Stmt} &
  ast.Assign{id: Assign, parentID: Stmt} &
  ast.Ident{parentID: Assign, span: S, text: Var} &
  ast.Expr{id: Expr, parentID: Assign}.

tc.typeAttr{struct: S, defnSpan: SP, attr: A, type: T} :-
  tc.typeAttr.fromBind{struct: S, defnSpan: SP, attr: A, type: T} |
  tc.typeAttr.fromTypeDecl{struct: S, defnSpan: SP, attr: A, type: T}.
tc.typeAttr.fromBind{struct: S, defnSpan: SP, attr: A, type: T} :-
  util.AssignExpr{assign: Stmt, expr: Expr} &
  ast.Bind{id: Bind, parentID: Expr} &
  ast.Ident{parentID: Bind, text: S} &
  ast.Attrs{id: Attrs, parentID: Bind} &
  ast.Attr{id: Attr, parentID: Attrs} &
  ast.Ident{parentID: Attr, tag: "key", span: SP, text: A} &
  ast.Ident{parentID: Attr, tag: "value", text: Var} &
  scope.Parent{parentID: ParentScope, childID: Stmt} &
  scope.Item{scopeID: ParentScope, name: Var, type: T}.
tc.typeAttr.fromTypeDecl{struct: S, defnSpan: SP, attr: A, type: T} :-
  ast.TypeDecl{id: Decl} &
  ast.Ident{parentID: Decl, text: S} &
  ast.MemberDecls{id: MemberDecls, parentID: Decl} &
  ast.MemberDecl{id: MemberDecl, parentID: MemberDecls} &
  ast.Ident{parentID: MemberDecl, tag: "name", span: SP, text: A} &
  ast.Ident{parentID: MemberDecl, tag: "type", text: T}.

# Parent

scope.Parent{parentID: First, childID: Second} :-
  ast.Statement{id: First} &
  ast.Statement{id: Second} &
  astInternal.next{prev: First, next: Second}.

# Problem

tc.Problem{desc: D, span: S} :-
  tc.undefinedVar{desc: D, span: S}.
tc.undefinedVar{desc: undefinedVar{name: N}, span: S} :-
  scope.Var{scopeID: I, name: N, span: S, kind: K} &
  !scope.Item{scopeID: I, name: N, kind: K}.

# Highlight

hl.mapping{rule: "Ident", type: "ident"}.
hl.mapping{rule: "NumberLit", type: "number"}.
hl.mapping{rule: "BoolLit", type: "number"}.
hl.mapping{rule: "StringLit", type: "string"}.
hl.mapping{rule: "comment", type: "comment"}.
hl.mapping{rule: "addKW", type: "keyword"}.
hl.mapping{rule: "setKW", type: "keyword"}.
hl.mapping{rule: "ruleKW", type: "keyword"}.
hl.mapping{rule: "typeKW", type: "keyword"}.
