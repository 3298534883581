type rawNode {
  id: int
}
type Node {
  next: Node
  prev: Node
  id: int
}

rule {
  raw_node = rawNode()
  id = raw_node.id
  edge = Edge()
  edge == id
  node = Node.add(id=id)
  x = node.next.next.id
}